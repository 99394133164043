body.before-load {
  height: 100vh;
  overflow: hidden;
  opacity: 0
}

.white-link {
  color: #fff;
  text-decoration: none
}

html, body {
  padding: 0;
  margin: 0;
  overflow-x: hidden
}

.container {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto
}

.m-menu__button {
  display: none
}

.menu-auth__item:hover {
  opacity: .7
}

a {
  color: #fff;
  text-decoration: none !important;
  font-size: 16px;
}

.header {
  position: relative;
  padding: 20px 40px 70px;
  background: linear-gradient(138.67deg, #39BF93 18.68%, #79F2B7 90.47%)
}

.header .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column
}

.header .section__bottom-line {
  background-image: url(../img/home/Line1.png)
}

.header__logo {
  width: 100px;
  height: 40px;
  margin-right: 40px
}

.header__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.header__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1
}

.header .menu-auth {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto
}

.header .menu-auth__item {
  width: 189px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  border: 1px solid #FFFFFF;
  border-radius: 20px;
  text-decoration: none;
  padding: 9px 0;
  margin-right: 20px;
  -webkit-transition: opacity .2s ease-in-out;
  transition: opacity .2s ease-in-out
}

.header .menu-auth__item:last-child {
  margin-right: 0
}

.header .menu-auth__item--reg {
  color: #fff;
  background-color: transparent
}

.header .menu-auth__item--login {
  color: #39BF93;
  background-color: #fff
}

.header__banner {
  margin-top: 50px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch
}

.header__banner-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 43%;
  padding: 120px 0;
  margin-right: 35px
}

.header__banner-text-title {
  font-weight: bold;
  font-size: 50px;
  line-height: 65px;
  color: #fff;
  margin-bottom: 30px
}

.header__banner-text-content {
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #fff
}

.header__banner-text .separator-line {
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto
}

.header__banner-image {
  position: relative;
  width: calc(57% - 35px);
  height: 688px
}

.header__banner-image-phone {
  position: absolute;
  bottom: 79px;
  left: 0;
  max-width: 211px;
  z-index: 4
}

.header__banner-image-ipad {
  position: absolute;
  left: 72px;
  top: 46px;
  max-width: 622px;
  z-index: 2
}

.header__banner-image-heart {
  position: absolute;
  left: 279px;
  top: 0;
  max-width: 144px;
  z-index: 3
}

.header__banner-image-star {
  position: absolute;
  left: 164px;
  bottom: 261px;
  max-width: 135px;
  z-index: 5
}

.header__banner-image-coin-1 {
  position: absolute;
  top: 64px;
  left: 500px;
  max-width: 78px;
  z-index: 1
}

.header__banner-image-coin-2 {
  position: absolute;
  left: 434px;
  bottom: 348px;
  max-width: 41px;
  z-index: 3
}

.header__banner-image-coin-3 {
  position: absolute;
  max-width: 121px;
  top: 415px;
  left: 450px;
  z-index: 3
}

.header__banner-image-coin-4 {
  position: absolute;
  max-width: 80px;
  bottom: 37px;
  left: 87px;
  z-index: 5
}

.header__banner-image-remote-control {
  position: absolute;
  max-width: 251px;
  bottom: 0;
  left: 264px;
  z-index: 5
}

.menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 60px
}

.menu__item {
  margin-right: 40px;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  text-decoration: none;
  -webkit-transition: opacity .2s ease-in-out;
  transition: opacity .2s ease-in-out
}

.menu__item:hover,
.menu__item--payments:hover, 
.menu__item--loan:hover, 
.menu__item--p2p:hover {
  opacity: 0.7
}

.menu__item:last-child {
  margin-right: 0
}

.numbers {
  padding: 160px 50px 170px;
  background-color: #FFFFFF
}

.numbers__circles {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.numbers__circle {
  width: 280px;
  height: 280px;
  border-radius: 50%;
  background: #f5f4f4;
  border: 1px solid #39BF93;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-negative: 0;
  flex-shrink: 0
}

.numbers__circle:nth-of-type(1) {
  z-index: 1
}

.numbers__circle:nth-of-type(2) {
  z-index: 2
}

.numbers__circle:nth-of-type(1), .numbers__circle:nth-of-type(2) {
  margin-right: -30px
}

.numbers__circle:nth-of-type(4) {
  margin-left: -30px;
  z-index: 2
}

.numbers__circle:nth-of-type(5) {
  margin-left: -30px;
  z-index: 1
}

.numbers__circle-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 350px;
  height: 350px;
  background: linear-gradient(162.13deg, #39BF93 9.37%, #79F2B7 121.2%);
  border: 1px solid #39BF93;
  border-radius: 50%;
  z-index: 3;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  z-index: 9
}

.numbers__circle-main-img {
  width: 159px;
  height: 150px
}

.numbers__circle-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #303233
}

.numbers__circle .separator-line {
  margin-top: 24px;
  margin-bottom: 8px
}

.numbers__circle-num {
  font-size: 50px;
  line-height: 65px;
  color: #303233
}

.numbers__list {
  margin-top: 170px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.numbers__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.numbers__item-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.numbers__item:last-child:after {
  display: none
}

.numbers__item:after {
  content: "";
  display: block;
  background-image: url(../img/home/arrow-right.svg);
  width: 60px;
  height: 44px;
  margin-left: 50px;
  margin-right: 50px
}

.numbers__item-num {
  font-size: 50px;
  line-height: 65px;
  color: #303233
}

.numbers__item-text {
  font-size: 30px;
  line-height: 39px;
  text-align: center;
  color: #303233
}

.coins {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  background: linear-gradient(162.13deg, #39BF93 9.37%, #79F2B7 121.2%);
  padding: 120px 0 130px 180px
}

.coins .section__bottom-line {
  background-image: url(../img/home/Line2.png)
}

.coins__image {
  width: 33%;
  max-width: 481px;
  margin-right: 74px
}

.coins__content {
  padding-left: 50px;
  width: 67%;
  max-width: 511px
}

.coins__content-title {
  font-weight: bold;
  font-size: 40px;
  line-height: 52px;
  color: #fff;
  margin-bottom: 30px
}

.coins__content-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-size: 18px;
  line-height: 28px;
  color: #fff;
  margin-bottom: 30px
}

.coins__content-list-item:last-child {
  margin-bottom: 0
}

.coins__content-list-item:before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 7px;
  background-color: #fff;
  -ms-flex-negative: 0;
  flex-shrink: 0
}

#coins-animation-container {
  display: flex;
}

.services {
  padding-top: 135px;
  padding-bottom: 130px;
  background-color: #f5f4f4
}

.services__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 130px
}

.coins__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.services__item:last-child {
  margin-bottom: 0
}

.services__item-content {
  width: 46%
}

.services__item-title {
  font-weight: bold;
  font-size: 40px;
  line-height: 52px;
  color: #303233;
  margin-bottom: 30px
}

.services__item-list-item {
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 24px;
  color: #303233;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.services__item-list-item:before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  margin-right: 10px;
  background-color: #39BF93;
  border-radius: 50%
}

.services__item-list-item:last-child {
  margin-bottom: 0
}

.services__item-image {
  width: calc(54% - 147px);
  margin-left: 147px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 622px
}

.services__item-image-wrapper {
  max-width: 100%;
  position: relative
}

.services__item-image-wrapper img {
  max-width: 100%
}

.services__item-image-bubble {
  position: absolute;
  border-radius: 50%;
  background: #FFFFFF;
  border: 1px solid #303233
}

.services__item-image-main {
  position: relative;
  height: 100%;
  max-height: 600px;
  z-index: 2
}

.services__item:nth-child(even) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse
}

.services__item:nth-child(even) .services__item-image {
  margin-left: 0;
  margin-right: 147px
}

.payments-type {
  padding: 70px 0 80px;
  background: linear-gradient(162.13deg, #39BF93 9.37%, #79F2B7 121.2%);
  border-bottom: 1px solid #39BF93
}

.payments-type__title {
  font-weight: bold;
  font-size: 40px;
  line-height: 52px;
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 40px
}

.partners {
  padding: 130px 0
}

.partners .section__bottom-line {
  background-image: url(../img/home/Line3.png)
}

.partners__title {
  font-weight: bold;
  font-size: 40px;
  line-height: 52px;
  text-align: center;
  color: #303233
}

.partners__main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 70px 0
}

.partners__main-image {
  margin-right: 104px
}

.partners__main-text {
  max-width: 454px;
  font-size: 18px;
  line-height: 28px;
  color: #303233
}

.partners__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.partners__list-item {
  max-width: 169px;
  max-height: 69px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.partners__list-item img {
  max-width: 100%;
  max-height: 100%
}

.reviews {
  padding: 70px 0;
  background-color: #f5f4f4
}

.reviews__title {
  font-weight: bold;
  font-size: 40px;
  line-height: 52px;
  text-align: center;
  color: #303233;
  margin-bottom: 50px
}

.reviews__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.reviews__item {
  width: 500px;
  margin-left: 18px;
  margin-right: 18px;
  overflow: hidden;
  background: #fff;
  border: 1px solid #C2C9CC;
  border-radius: 20px
}

.reviews__item-header {
  background: #f5f4f4;
  border-bottom: 1px solid #C2C9CC;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 26px 30px
}

.reviews__item-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px
}

.reviews__item-avatar img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

.reviews__item-client-name {
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #303233;
  margin-bottom: 2px
}

.reviews__item-client-position {
  font-size: 12px;
  line-height: 16px;
  color: #303233
}

.reviews__item-socials {
  margin-left: auto
}

.reviews__item-content {
  padding: 28px 30px
}

.reviews__item-text {
  font-size: 18px;
  line-height: 28px;
  color: #303233
}

.cta {
  padding: 70px 0;
  background: linear-gradient(161.9deg, #39bf93 9.46%, #79f2b7 109.54%)
}

.cta .container {
  display: flex;
  align-items: center;
  justify-content: center
}

.cta__block {
  width: 100%
}

.cta__block-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  color: #fff;
  margin-bottom: 26px;
  text-align: center
}

.cta__block-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: center;
  align-items: center
}

.cta__image {
  flex-shrink: 1;
  margin-left: 180px
}

.cta__image img {
  max-width: 100%;
  max-height: 654px
}

.cta__link {
  width: 189px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  border: 1px solid #39BF93;
  border-radius: 20px;
  text-decoration: none;
  padding: 9px 0;
  margin-right: 20px
}

.cta__link:last-child {
  margin-right: 0
}

.cta__link--reg {
  border: 1px solid #fff;
  color: #fff;
  background-color: transparent
}

.cta__link--login {
  color: #39bf93;
  background-color: #fff
}

.cta .section__bottom-line {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAACDAAAAAWCAMAAAAL6g7bAAABlVBMVEUAAAAAAAAAAABVVVVAQEAzMzMrKyskJCRAQEA5OTkzMzMuLi4rKysnOzs3NzczMzMwMDArOTk2NjYzMzMuLi4sNzc1NTUzMzMxMTEvLy8uLjcsNTUzMzMxMTEwMDAuLjYzMzMyMjIwMDAuNDQzMzMyMjIxMTEvLzUtMzMxMTEwMDUuMzMxMTEwMDUvNDQxMTEwMDUvMzMxMTUvMzMuMjIxMTExMTUvMzMxMTQwNDQwMzMuMjIxMTQxMTQwMjIxMTMxMzMwMzMvMTMwMjQxMzMvMTMvMTMwMjQvMjIwMjMwMzMwMjMwMjIvMjQxMjMwMjMvMjQwMjMwMTMwMjQxMjMwMjMwMjIwMjQvMjMwMjMwMTMwMjIwMjQwMjMxMjMwMzMwMjMxMjMwMzMwMjMvMjMwMjMwMzMwMjMwMjMwMjMwMjMwMjMxMjMwMzMwMjMwMjMvMjMwMjMwMjMwMjMwMTMwMjMwMjMwMjMwMjMwMjMwMjMwMjMwMjMvMjMwMjMwMjMwMjMwMjMwMjMwMjMwMjMwMjP////G9yTRAAAAhXRSTlMAAQIDBAUGBwgJCgsMDQ4PEBITFBYXGBkaGxwdHh8gISMkJScoKSorLS8wMjQ1Njk6PD9BQkNERklKS01OXmZtbm93gIiMkZmiqquztre4uby+v8HCw8XGx8jJysvMzc/Q0tTW19jZ2tzd3+Hi4+Xm5+nr7O3v8PHy8/T19vf4+fr7/P3+/4TWrAAAAAFiS0dEhozeO10AAAMpSURBVHja7ZrtOxRRGIcHi96p9KJXRVGSKJXWqrGsXauSUlEqsoWolJck5e35v/uA7cyZM+Nitg/muu+vz+86554vcz3nnMeyAAAAALYifd63XPuys8Svnhf7YOf7Bfa3D9b77lCe+t8KJQkUUEBhewrnfAPR9/Eiv3rEzsQsFFBAIVwKlvyKF3vWSxMrIl9jeZ6BK89FZLDO5xsmRNaSRz3rhfa85EDh9TUfhUkUUEBhmwoLPj+fim4Rydz23qFhSESeVKGAAgqhUrBE5OMdr4PKlIiISO8lr9uDZRERWe085PENj9YXmPY68DQOrwcCKywnDpoDFx+jgAIKO1AYumGuF7UtiojIWrLMHDicWBURkaX2PSiggEKIFCwREUmfMtQre2QT8ybRiWzgS8znG0RE+moMgePJtWwgsMJnk8Le+G8UUEBhRwrm08atkewCM3aBqaX5lg2MNqOAAgrhUVhvGGTWtcm++B9RGL2rL3DhoVqX3st64OaIWl9O6LcQ+bHvaiC4Qk+l6+85jgIKKOxYYbpVf/k4mXTs8Oa6vsPVF45A+iwKKKAQFgVrM/PWOe0Q/SQa6TPOhmRRqy8lDqiBE0l9Ae0WonZADwRW0F5+y1MooIBCIIVn1Wo9Ys9p9dXkETVQkljRAtqzKwoooLB7FbINg+Oqo6Jb3Pxsi2QDTRlDYOzev4akdc4QUAYuSl3fkBMF5eW30J5HAQUUAiqoB4GGIcMC6vxldNIQyDShgAIKoVCw1KuOjWkHdfbAwbt64w2I+6qj9pW5vvnsmp3c0gmukDrmHBJBAQUUAimMNTvHplw8rXaOTbnbnjIUUEAhBAqWI9NX45yfMB54IvYPz8BCvNijJ1IHLpTJLReBFWbsAufwGAoooBBIIX3aOTZlPPCoY1MooIBCGBUsbZOOqi7xYyrm1ZBsMNzo1RNt0FXVseRXD64wUHd/FgUUUMiZwtyDun7fBcZbWsZ9A/0ooIDCrlewBAAAAGALaBgAAACAhgEAAABoGAAAAICGAQAAAGgYAAAAgIYBAAAAaBgAAACAhgEAAACAhgEAAABoGAAAACBX/AXCKRjWYOlRiQAAAABJRU5ErkJggg==)
}


.footer {
  background-color: #303233;
  padding: 70px 0 30px
}

.footer__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.footer__top .menu__item {
  color: #f5f4f4;
  -webkit-transition: color .2s ease-in-out;
  transition: color .2s ease-in-out
}

.footer__top .menu__item:hover {
  color: #39BF93
}

.footer__logo {
  width: 100px;
  height: 40px;
  margin-right: 97px
}

.footer__logo img {
  width: 100%;
  height: 100%
}

.footer__socials {
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.footer__socials .social__item {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  margin-right: 23px
}

.footer__socials .social__item:last-child {
  margin-right: 0
}

.footer__socials .social__item img, .footer__socials .social__item svg {
  width: 100%;
  height: 100%;
  border-radius: 50%
}

.footer .social__item:hover {
  background-color: #39BF93
}

.footer .group_certificates {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.footer .gr_child {
  margin-left: 70px
}

.footer .pci-dss {
  margin-top: 133px;
  margin-bottom: 36px
}

.footer .audit-info {
  font-size: 16px;
  line-height: 28px;
  color: #9DA3A6;
  margin-bottom: 12px
}

.footer .additional-info {
  font-size: 12px;
  line-height: 16px;
  color: #9DA3A6
}

.footer__bottom {
  margin-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.footer__bottom-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto
}

.footer__bottom-link {
  font-size: 12px;
  line-height: 28px;
  color: #FFFFFF;
  text-decoration: none;
  margin-right: 58px
}

.footer__bottom-link:last-child {
  margin-right: 0
}

.footer__copyright {
  font-size: 12px;
  line-height: 28px;
  color: #FFFFFF
}

.section {
  position: relative
}

.section__bottom-line {
  position: absolute;
  bottom: 0;
  left: 0;
  min-width: 100%;
  height: 12px;
  background-size: contain;
  background-position: bottom;
  background-repeat: repeat-x
}

a.link {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  border: 1px solid #39BF93;
  border-radius: 20px;
  text-decoration: none;
  padding: 9px 20px;
  margin-right: 20px;
  margin-top: 25px;
  background-color: #39BF93;
  color: #ffffff;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear
}

a.link:hover {
  opacity: .8
}

.demo-link {
  width: 189px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  border: 1px solid #39BF93;
  color: #39BF93;
  border-radius: 20px;
  text-decoration: none;
  padding: 9px 0;
  -webkit-transition: opacity .2s ease-in-out;
  transition: opacity .2s ease-in-out
}

.h-d {
  display: none !important
}

@media screen and (max-width: 1440px) {
  .h-d {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
  }

  .coins {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0
  }

  .numbers {
    padding: 90px 24px 140px
  }

  .numbers__list {
    margin-top: 60px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }

  .numbers__item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }

  .numbers__item:after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    margin: 30px 0
  }

  .numbers__item-num {
    font-size: 40px;
    line-height: 52px
  }

  .numbers__item-text {
    font-size: 20px;
    line-height: 26px
  }

  .numbers__circles {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }

  .numbers__circle {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    width: 240px;
    height: 240px;
    margin: 0 !important;
    margin-top: -20px !important
  }

  .numbers__circle:nth-child(1) {
    z-index: 3
  }

  .numbers__circle:nth-child(2) {
    z-index: 2
  }

  .numbers__circle:nth-child(4) {
    z-index: 1
  }

  .numbers__circle:nth-child(5) {
    z-index: 0
  }

  .numbers__circle-title {
    font-size: 16px;
    line-height: 21px
  }

  .numbers__circle .separator-line {
    margin-top: 11px;
    margin-bottom: 8px
  }

  .numbers__circle-num {
    font-size: 40px;
    line-height: 52px
  }

  .numbers__circle-info {
    font-size: 16px;
    line-height: 21px
  }

  .numbers__circle-main {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    width: 240px;
    height: 240px
  }

  .numbers__circle-main-img {
    width: 108px;
    height: 103px
  }

  .coins {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 100px 0
  }

  .coins__image {
    width: 100%;
    max-width: 100%;
    margin-right: 0
  }

  .coins__content {
    padding-left: 24px;
    width: 100%;
    max-width: 100%
  }

  .services {
    padding: 90px 0 120px
  }

  .services__item, .coins__item {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
    margin-bottom: 120px
  }

  .services__item:last-child {
    margin-bottom: 0
  }

  .services__item-image {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    width: 100%;
    margin: 0 0 50px 0 !important;
    max-width: 241px;
    height: auto
  }

  .services__item-image-main {
    height: auto
  }

  .services__item-content {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    width: 100%;
    padding: 0 24px
  }

  .services__item-title, .coins__content-title {
    font-size: 32px;
    line-height: 42px
  }

  .services__item-list-item {
    font-size: 16px;
    line-height: 24px
  }

  .partners {
    padding: 100px 24px 62px
  }

  .partners__title {
    font-size: 32px;
    line-height: 42px
  }

  .partners__main {
    margin-top: 50px;
    margin-bottom: 50px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }

  .partners__main-image {
    margin-right: 0;
    margin-bottom: 9px
  }

  .partners__main-image img {
    max-width: 100%
  }

  .partners__main-text {
    font-size: 16px;
    line-height: 24px;
    text-align: center
  }

  .partners__list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
  }

  .partners__list__item {
    margin: 0 10px 38px 10px;
  }

  .reviews {
    padding: 50px 0
  }

  .reviews__list {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
  }

  .reviews__title {
    font-size: 32px;
    line-height: 42px
  }

  .reviews__item {
    width: 312px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-left: 12px;
    margin-right: 12px
  }

  .reviews__item-header {
    padding: 26px 12px
  }

  .reviews__item-content {
    padding: 28px 12px
  }

  .cta {
    padding: 70px 0;
    background: linear-gradient(161.9deg, #39bf93 9.46%, #79f2b7 109.54%)
  }
  
  .cta .container {
    display: flex;
    align-items: center;
    justify-content: center
  }
  
  .cta__block {
    width: 100%
  }
  
  .cta__block-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 52px;
    color: #fff;
    margin-bottom: 26px;
    text-align: center
  }
  
  .cta__block-actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    align-items: center
  }
  
  .cta__image {
    flex-shrink: 1;
    margin-left: 180px
  }
  
  .cta__image img {
    max-width: 100%;
    max-height: 654px
  }
  
  .cta__link {
    width: 189px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    border: 1px solid #39BF93;
    border-radius: 20px;
    text-decoration: none;
    padding: 9px 0;
    margin-right: 20px
  }
  
  .cta__link:last-child {
    margin-right: 0
  }
  
  .cta__link--reg {
    border: 1px solid #fff;
    color: #fff;
    background-color: transparent
  }
  
  .cta__link--login {
    color: #39bf93;
    background-color: #fff
  }
  
  .cta .section__bottom-line {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAACDAAAAAWCAMAAAAL6g7bAAABlVBMVEUAAAAAAAAAAABVVVVAQEAzMzMrKyskJCRAQEA5OTkzMzMuLi4rKysnOzs3NzczMzMwMDArOTk2NjYzMzMuLi4sNzc1NTUzMzMxMTEvLy8uLjcsNTUzMzMxMTEwMDAuLjYzMzMyMjIwMDAuNDQzMzMyMjIxMTEvLzUtMzMxMTEwMDUuMzMxMTEwMDUvNDQxMTEwMDUvMzMxMTUvMzMuMjIxMTExMTUvMzMxMTQwNDQwMzMuMjIxMTQxMTQwMjIxMTMxMzMwMzMvMTMwMjQxMzMvMTMvMTMwMjQvMjIwMjMwMzMwMjMwMjIvMjQxMjMwMjMvMjQwMjMwMTMwMjQxMjMwMjMwMjIwMjQvMjMwMjMwMTMwMjIwMjQwMjMxMjMwMzMwMjMxMjMwMzMwMjMvMjMwMjMwMzMwMjMwMjMwMjMwMjMwMjMxMjMwMzMwMjMwMjMvMjMwMjMwMjMwMjMwMTMwMjMwMjMwMjMwMjMwMjMwMjMwMjMwMjMvMjMwMjMwMjMwMjMwMjMwMjMwMjMwMjMwMjP////G9yTRAAAAhXRSTlMAAQIDBAUGBwgJCgsMDQ4PEBITFBYXGBkaGxwdHh8gISMkJScoKSorLS8wMjQ1Njk6PD9BQkNERklKS01OXmZtbm93gIiMkZmiqquztre4uby+v8HCw8XGx8jJysvMzc/Q0tTW19jZ2tzd3+Hi4+Xm5+nr7O3v8PHy8/T19vf4+fr7/P3+/4TWrAAAAAFiS0dEhozeO10AAAMpSURBVHja7ZrtOxRRGIcHi96p9KJXRVGSKJXWqrGsXauSUlEqsoWolJck5e35v/uA7cyZM+Nitg/muu+vz+86554vcz3nnMeyAAAAALYifd63XPuys8Svnhf7YOf7Bfa3D9b77lCe+t8KJQkUUEBhewrnfAPR9/Eiv3rEzsQsFFBAIVwKlvyKF3vWSxMrIl9jeZ6BK89FZLDO5xsmRNaSRz3rhfa85EDh9TUfhUkUUEBhmwoLPj+fim4Rydz23qFhSESeVKGAAgqhUrBE5OMdr4PKlIiISO8lr9uDZRERWe085PENj9YXmPY68DQOrwcCKywnDpoDFx+jgAIKO1AYumGuF7UtiojIWrLMHDicWBURkaX2PSiggEKIFCwREUmfMtQre2QT8ybRiWzgS8znG0RE+moMgePJtWwgsMJnk8Le+G8UUEBhRwrm08atkewCM3aBqaX5lg2MNqOAAgrhUVhvGGTWtcm++B9RGL2rL3DhoVqX3st64OaIWl9O6LcQ+bHvaiC4Qk+l6+85jgIKKOxYYbpVf/k4mXTs8Oa6vsPVF45A+iwKKKAQFgVrM/PWOe0Q/SQa6TPOhmRRqy8lDqiBE0l9Ae0WonZADwRW0F5+y1MooIBCIIVn1Wo9Ys9p9dXkETVQkljRAtqzKwoooLB7FbINg+Oqo6Jb3Pxsi2QDTRlDYOzev4akdc4QUAYuSl3fkBMF5eW30J5HAQUUAiqoB4GGIcMC6vxldNIQyDShgAIKoVCw1KuOjWkHdfbAwbt64w2I+6qj9pW5vvnsmp3c0gmukDrmHBJBAQUUAimMNTvHplw8rXaOTbnbnjIUUEAhBAqWI9NX45yfMB54IvYPz8BCvNijJ1IHLpTJLReBFWbsAufwGAoooBBIIX3aOTZlPPCoY1MooIBCGBUsbZOOqi7xYyrm1ZBsMNzo1RNt0FXVseRXD64wUHd/FgUUUMiZwtyDun7fBcZbWsZ9A/0ooIDCrlewBAAAAGALaBgAAACAhgEAAABoGAAAAICGAQAAAGgYAAAAgIYBAAAAaBgAAACAhgEAAACAhgEAAABoGAAAACBX/AXCKRjWYOlRiQAAAABJRU5ErkJggg==)
  }

  @media screen and (max-width: 1140px) { 
    .header {
      padding: 24px
    }
  
    .header__nav {
      position: fixed;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
      background: #f5f4f4;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-transition: -webkit-transform .2s ease-in-out;
      transition: -webkit-transform .2s ease-in-out;
      transition: transform .2s ease-in-out;
      transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
      z-index: 99;
      padding: 60px 24px
    }
  
    .header__nav.opened {
      -webkit-transform: translateX(0);
      transform: translateX(0)
    }
  
    .header .menu {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      margin-right: 0
    }
  
    .header .menu__item {
      color: #39BF93;
      margin-right: 0;
      margin-bottom: 30px
    }
  
    .header .menu__item:last-child {
      margin-right: 0
    }
  
    .header .menu-auth {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      margin-left: 0;
      margin-top: 50px
    }
  
    .header .menu-auth__item {
      margin-bottom: 20px;
      margin-right: 0
    }
  
    .header .menu-auth__item--reg {
      border: 1px solid #39BF93;
      background: #fff;
      color: #39BF93;
      margin-right: 0
    }
  
    .header .menu-auth__item--login {
      background: #39BF93;
      color: #fff
    }
  
    .header .m-menu__button {
      display: block;
      margin-left: auto;
      z-index: 9999
    }
  
    .header .m-menu__button.opened {
      position: fixed;
      top: 30px;
      right: 24px
    }
  
    .header .m-menu__button.opened svg path {
      fill: #39BF93
    }
  
    .header__banner {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column
    }
  
    .header__banner-text {
      width: 100%;
      padding: 0;
      margin: 0 0 30px 0
    }
  
    .header__banner-text-title {
      font-size: 40px;
      line-height: 52px;
      margin-bottom: 20px
    }
  
    .header__banner-text-content {
      font-size: 16px;
      line-height: 24px
    }
  
    .header__banner-text .separator-line {
      display: none
    }
  
    .header__banner-image {
      width: 100%
    }

    .menu__item--p2p {
      margin-top: 20px;
      color: #39BF93;
    }

    .footer {
      padding: 50px 24px 10px
    }
  
    .footer__top {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap
    }
  
    .footer__logo {
      width: 100%;
      margin-right: 0;
      margin-bottom: 36px
    }
  
    .footer__logo img {
      width: 100px;
      height: 40px
    }
  
    .footer__nav .menu {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap
    }
  
    .footer__nav .menu__item {
      width: calc(50% - 20px);
      margin-right: 20px;
      margin-bottom: 31px
    }
  
    .footer__socials {
      display: none
    }
  
    .footer .pci-dss {
      margin-top: 5px;
      margin-bottom: 20px
    }
  
    .footer .audit-info {
      font-size: 16px;
      line-height: 21px;
      margin-bottom: 20px
    }
  
    .footer .additional-info {
      font-size: 10px;
      line-height: 13px
    }
  
    .footer__bottom {
      margin-top: 26px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  
    .footer__bottom-links {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: left;
      -ms-flex-align: left;
      align-items: left;
      margin-left: 0;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap
    }
  
    .footer__bottom-link {
      width: 100%;
      margin-bottom: 17px
    }
  
    .footer__bottom-link:last-child {
      margin-bottom: 0
    }
  
    .footer__copyright {
      width: 100%;
      text-align: center;
      margin-top: 26px;
      color: #797D80;
      font-size: 12px;
      line-height: 28px
    }

    .footer__socials {
      display: none
    }
  }
  @media screen and (max-width: 768px) {  
    .container {
      padding: 0 24px
    }
       
    .cta {
      padding: 48px 0
    }
  
    .cta__block-title {
      font-size: 32px;
      line-height: 42px;
      margin-bottom: 24px
    }
  
    .cta__block-actions {
      flex-direction: column
    }
  
    .cta__link {
      width: 100%;
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-bottom: 16px
    }
    
    .cta__link:last-child {
      margin-bottom: 0
    }

    .menu__item--p2p {
      margin-top: 20px;
      color: #39BF93;
    }
  }
}
